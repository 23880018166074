.page-not-found {
    width: 100%;
    background-color: var(--color-balticSea);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 90px 0px;
    flex-grow: 1;
    z-index: 1;
}
.page-not-found__content {
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-not-found__title {
    font-family: 'MTSWideMedium';
    color: var(--color-vividTangerine);
    font-size: 84px;
    line-height: 84px;
    height: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.page-not-found__subtitle {
    color: var(--color-white);
    font: var(--font-h3-wide);
    text-align: center;
    margin-bottom: 16px;
}

.page-not-found__description {
    color: var(--color-alabaster);
    font: var(--font-p2-regular-comp);
    text-align: center;
}

.page-not-found__link {
    color: var(--color-lightBlueberry);
}

@media (min-width: 768px) {
    .page-not-found__title {
        font-size: 144px;
        line-height: 44px;
        margin-bottom: 24px;
    }

    .page-not-found__subtitle {
        font: var(--font-h2-wide);
    }

    .page-not-found__description {
        font: var(--font-p2-regular-comp);
    }
}
